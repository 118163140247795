import React, { useState, useEffect } from "react";
import Button from "react-bootstrap/Button"
import "./dictionary.css"
import { forwardRef, useRef } from "react"
import Modal from "./Modal";
import Pdf from "./pdfViewer";
import Image from "./ImageViewer"
import Video from "./movieViewer"

const customStyles = {
    content: {
      top: "20%",
      left: "50%",
      right: "auto",
      bottom: "auto",
      marginRight: "-50%",
      transform: "translate(-50%, -50%)",
      minWidth: "100%",
    },
  };
  

const DictModal = ( props ) => {
    const pdfs = props.content.pdf == undefined ? [] : props.content.pdf.split( "\n" );
    const images = props.content.image == undefined ? [] : props.content.image.split( "\n" );
    const movies = props.content.movie == undefined ? [] : props.content.movie.split( "\n" );
    
    const close = () => {
        props.setShow( false );
    }

    return (
        <Modal  show = {props.showState}
                style = {customStyles}
                title = {props.content.word}
                body = {
                    <div>
                        <p>{props.content.mean}</p>
                        {pdfs.map( pdf => (
                            <p><Pdf link={pdf}>{pdf}</Pdf></p>
                        ))}
                        
                        <Image files={images}></Image>
                        
                        {movies.map( movie => (
                            <Video link={movie}></Video>
                        ))}
                    </div>
                }
                footer={<Button onClick={close}>Close</Button>}
        />
    )
}

export default DictModal;