import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";

const Login = () => {
    const navigate = useNavigate();
    const redirect = ( pass, userData ) => {
        // URLから割り出したユーザー情報の転送
        navigate( pass, {state : { user : userData} } );
    }
    useEffect(() => {
        (()=> {
            const url = window.location.href.split( "?" );
            const homePass = `/home?${url[1]}?${url[2]}`;
       
            const formData = new FormData;
            formData.set( "ID", url[1] );
            formData.set( "hash", url[2] );
            const idData = {
                method : "POST",
                body : formData
            };
            
            fetch( "https://integration.paztria.com/cgi/User/fetchUser.php", idData )
            .then( res => res.text() )
            .then( res => {
                const fetchData = JSON.parse( res );
                if( fetchData["ID"] != "" ) {
                    redirect( homePass, fetchData );
                }
            })
        })();
    }, [] );

    return <></>
};

export default Login;