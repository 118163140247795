import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from "react-bootstrap/Button"
import Menu from "./Menu"
import Calendar from './calendar'

const Home = () => {

    const [ userData, setUser ] = useState([]);
    const [ dictionaryPass, setDictionaryPass ] = useState("");
    const location = useLocation();
    const navigate = useNavigate();
    const dictionaryNavigation = () => {
        console.log( userData );
        navigate( dictionaryPass, {state : { user : userData} });
    }
        
    useEffect(() => {
        setUser( location.state.user );
        const url = window.location.href.split( "?" );
        setDictionaryPass( `/dictionary?${url[1]}?${url[2]}` );
    }, [] );
    
    return (
        <div>
            <Menu/>
            <Calendar/>
        </div>
    )
}

export default Home;