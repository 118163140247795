import React from "react";
import ReactImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

const ImageViewer = ( props ) => {
    const images = [];

    props.files.map( image => {
        if( image == "" ) return;
        images.push({
            "original" : "https://integration.paztria.com/material/image/" + image,
            "thumbnail" : "https://integration.paztria.com/material/image/thumbnail/" + image
            
        })
    });
    
    return (
        <p>
            <div>
                <ReactImageGallery
                    items={images}
                    showNav={false}
                    autoPlay={false}
                    showFullscreenButton={false}
                    useBrowserFullscreen={false}
                    showPlayButton={false}
                />
            </div>
        </p>
    )
}

export default ImageViewer