import React, { Component, useState, useEffect } from 'react';
import { Navbar, Nav } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom'
import LOGO from '../logo.svg';

const Menu = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const [ home, setHome ] = useState("");
    const homeNavigation = () => {
        navigate( home );
    }

    const [ userData, setUser ] = useState([]);
    const [ dictionary, setDictionary ] = useState("");
    const dictionaryNavigation = () => {
        navigate( dictionary, {state : { user : userData} });
    }

    const [ stock, setStock ] = useState( "" );
    const stockNavigation = () => {
        navigate( stock );
    }

    useEffect( () => {
        (
            () => {
                const getUser = window.location.href.split( "?" )[1]+"?"+window.location.href.split( "?" )[2];
                setHome( `/?${getUser}`);
                setDictionary( `/dictionary?${getUser}` );
                setStock( `/stockManagement?${getUser}` );
                //setUser( location.state.user );
            }
        )()
    }, []);
    
    return (
        <Navbar collapseOnSelect expand="ig" bg="dark" variant='dark'>
            <Navbar.Brand href='#home'>
                <img
                    src={LOGO}
                    width="30"
                    height="30"
                    alt='React Bootstrap logo'
                />
                
            </Navbar.Brand>
            <Navbar.Toggle aria-controls='responsive-navbar-nav' />
            <Navbar.Collapse id="responsive-navbar-nav" >
                <ul className="nav navbar-nav">
                    <li onClick={homeNavigation}><a href="">工程</a></li>
                    <li onClick={dictionaryNavigation}><a href="">辞書</a></li>
                    <li onClick={stockNavigation}><a href="">在庫登録</a></li>
                </ul>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default Menu;