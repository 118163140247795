import React, { Component } from 'react'
import ReactPlayer from 'react-player'
import movie1 from "../movie/index_movie.mp4"

const movieViewer = ( props ) => {
    if( props.link == "" || props.link == undefined ) return;
    const url = "https://integration.paztria.com/material/movie/"+props.link;
    return (
        <p>
            <video controls width={"100%"} height="auto">
                <source src={url}></source>
            </video>
        </p>
    )   
}

export default movieViewer;