import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import Menu from "./Menu"
import Select from "react-select"
import "react-bootstrap"
import 'bootstrap/dist/css/bootstrap.min.css'
import "./dictionary.css"
import Inputbox from "./inputBox"
import DictModal from './dictModal'
import SelectButton from "./selectContent"
import Frequently from "./FrequentlyOccurring"


// 連想配列のソート用関数
function addociativeArray_sort( a, b ) {
    if( a["読み方"]["value"] > b["読み方"]["value"] ) {
        return 1;
    }
    if( a["読み方"]["value"] < b["読み方"]["value"] ) {
        return -1;
    }
    return 0;
}


// 配列内にキーワードを含むかどうかの確認
function check_include_to_keyword( record ) {
    const conditions = document.getElementById( "conditions" ).value;
    if( record["言葉"]["value"].includes(conditions) ) return true;
    if( record["読み方"]["value"].includes(conditions) ) return true;
    if( record["意味"]["value"].includes(conditions) ) return true;
    if( record["会社"]["value"].includes(conditions) ) return true;
    
    return false;
}

// 選択中の工法、会社名によって表示する内容を変更するため、変数を返す
function extraction_dictionary_record( element ) {
    return {
        number : element["レコード番号"]["value"],
        word : element["言葉"]["value"],
        read : element["読み方"]["value"],
        mean : element["意味"]["value"],
        company : element["会社"]["value"],
        construction : element["工法"]["value"],
        pdf : element["PDF"]["value"],
        image : element["画像"]["value"],
        movie : element["動画"]["value"]
    }
}

function extraction_construction_record( element ) {
    return {
        value : element["工法"]["value"], label : element["工法"]["value"]
    }
}

function extraction_company_record( element ) {
    return {
        value : element["会社"]["value"], label : element["会社"]["value"]
    }
}

const Dictionary = () => {
    const [ userData, setUser ] = useState([]);
    const location = useLocation();
    const navigate = useNavigate();

    const [ kintoneData, setKintoneData ] = useState([]);
    const [ viewData, setViewData ] = useState([{
        number: 0,
        word: "",
        read : "",
        mean : "",
        company : "",
        construction : "",
        pdf : "",
        image : "",
        movie : ""
    }]);

    const [ companyData, setCompany ] = useState([{value : "", label : ""}]);
    const [ selectedCompany, setSelectedCompany ] = useState("");
    useEffect(() => {
        // 会社名に応じて工法の出力、辞書の出力データを減らす
        const records = [], constructionRecords = [{value : "", label : ""}];
        kintoneData.map( record => {
            if( selectedCompany == "" || record["会社"]["value"] == selectedCompany ) {
                records.push( extraction_dictionary_record(record) );
                constructionRecords.push( extraction_construction_record(record));
            } 
        });
        setViewData( records );
        setConstruction( Array.from( new Map( constructionRecords.map(( construction ) => [ construction.value, construction ] )).values() ));
    }, [selectedCompany] );
    

    const [ constructionData, setConstruction ] = useState([{value : "", label : ""}]);
    const [ selectedConstruction, setSelectedConstruction ] = useState( "" );
    useEffect(() => {
        const records = [];
        kintoneData.map( record => {
            if( ( selectedCompany == "" || record["会社"]["value"] == selectedCompany ) &&
                ( selectedConstruction == "" || record["工法"]["value"] == selectedConstruction ) ) {
                records.push( extraction_dictionary_record(record) );
            }
        });
        setViewData( records );
    }, [selectedConstruction]);
    
    const [ state, setState ] = useState( "" );
    useEffect(() => {
        setViewData( reword() );
    })

    const [ show, setShow ] = useState( false );
    const [ modalContent, setModalContent ] = useState( [] );
    const setModal = ( data ) => {
        setShow(true);
        setModalContent( data );
    }

    function reword() {
        const records = [], constructionRecords = [{value : "", label : ""}], companyRecords = [{value : "", label : ""}];
        kintoneData.forEach(element => {
    
            if( ( selectedCompany == "" || element["会社"]["value"] == selectedCompany ) &&
            ( selectedConstruction == "" || element["工法"]["value"] == selectedConstruction ) &&
            check_include_to_keyword( element ) ) {
                records.push(extraction_dictionary_record(element));
                constructionRecords.push( extraction_construction_record(element));
                companyRecords.push(extraction_company_record(element));
            };
        });
        return( records );
    }

    const fetchUser = () => {
        const url = window.location.href.split( "?" );
        const formData = new FormData;
        formData.set( "ID", url[1] );
        formData.set( "hash", url[2] );
        const idData = {
            method : "POST",
            body : formData
        };
        
        fetch( "https://integration.paztria.com/cgi/User/fetchUser.php", idData )
        .then( res => res.text() )
        .then( res => {
            const fetchData = JSON.parse( res );
            if( fetchData["ID"] != "" ) {
                setUser( fetchData );
            }
        })
    }


    const [ historyData, setHistoryData ] = useState( [] );
    const fetchHistory = ( records ) => {
        fetch( "https://integration.paztria.com/cgi/history/fetchHistory.php" )
        .then( res => res.text() )
        .then( res => {
            const note = [];
            const fetchHistoryData = JSON.parse( res )["records"];
            console.log( fetchHistoryData );
            fetchHistoryData.forEach( history => {
                note[history["単語"]["value"]] = ( note[history["単語"]["value"]] || 0 ) + 1;
            });
            
            const sortArray = Object.keys(note).sort(( a, b )=> {
                if( note[a] > note[b] ) {
                    return 1;
                }
                if( note[a] < note[b] ) {
                    return -1;
                }
                return 0;
                
            })

            const extractionArray = [];
            const FrequentlyArray = [];
            for( let i = 0; i < 4; i++ ) {
                extractionArray.push( sortArray.pop() );
            }

            extractionArray.forEach( e => {
                records.forEach( v => {
                    if( v["word"] != e ) return;
                    FrequentlyArray.push( v );
                })
            })

            

            /*
            viewData.forEach( e => {
                if( e["単語"]["value"] != ) 
            })

            extractionArray.forEach( e => {
                console.log( viewData.find( ({data}) => data["word"] == e ));
            });*/

            setHistoryData(FrequentlyArray);
            console.log( FrequentlyArray );
        })
    }

    useEffect( () => {
        (
            () => {
                fetchUser();
                
                fetch( "https://integration.paztria.com/cgi/fetchKintone.php" )
                .then( res => res.text() )
                .then( res => {
                    

                    const fetchData = JSON.parse( res );
                    let sortArray = Array.from( fetchData["records"] ).sort( addociativeArray_sort );
                    const records = [], constructionRecords = [{value : "", label : ""}], companyRecords = [{value : "", label : ""}];
                    setKintoneData( sortArray );
                    sortArray.forEach(element => {
                        records.push(extraction_dictionary_record(element));
                        constructionRecords.push( extraction_construction_record(element));
                        companyRecords.push(extraction_company_record(element));
                    });

                    setViewData( records );
                    setConstruction( Array.from( new Map( constructionRecords.map(( construction ) => [ construction.value, construction ] )).values() ));
                    setCompany( Array.from( new Map( companyRecords.map(( company ) => [ company.value, company ])).values() ));
                    fetchHistory( records );
                });

            }
        )()
    }, []);
    
    return (
        <div>
            <p>
                <Menu/>
                <Inputbox/>
                <p>               
                    <div style={{width:"75%", margin:"50px"}}>
                        会社選択：<Select
                            options={companyData}
                            defaultInputValue=''
                            onChange={(value) => {
                                setSelectedCompany(value.value);
                            }}  
                        />
                    </div>
                </p>
                <p>
                    <div style={{width:"75%", margin:"50px"}}>
                        工法選択：<Select
                            options={constructionData}
                            defaultInputValue=''
                            onChange={(value) => {
                                setSelectedConstruction(value.value);
                            }}  
                        />
                    </div>
                </p>
            </p>

            <div style={{width:"75%", margin:"10px"}}>
                よく検索される単語:
            </div>
            <p>
                {historyData.map ( data => (
                    <Frequently data={data} viewData={viewData} modalFunc={setModal}></Frequently>
                ))}
            </p>
                
            <div style={{width:"75%", margin:"10px"}}>
                検索結果:
            </div>
            {viewData.map( data => (
                <SelectButton data={data} modalFunc={setModal} user={userData}></SelectButton>
            ))}

            <DictModal showState={show} setShow={setShow} content={modalContent}/>
        </div>
    );
}

export default Dictionary;