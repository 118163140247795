import React , { useCallback, useState, useEffect } from "react"
import FullCalendar from "@fullcalendar/react"
import dayGridPlugin from "@fullcalendar/daygrid";
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';
import jaLocale from "@fullcalendar/core/locales/ja"
import calendarCSS from "./calendar.css"
import interactionPlugin, { DateClickArg } from "@fullcalendar/interaction";
import { useLocation, useNavigate } from 'react-router-dom'


const Calendar = ( props ) => {
    const [ holidays, setHolidays ] = useState([]);
    useEffect(() => {

    }, [holidays] );

    const navigate = useNavigate();


    useEffect( () => {
        (
            () => {
                fetch( "https://holidays-jp.github.io/api/v1/date.json" )
                .then( res => res.text() )
                .then( res => {
                    const fetchData = JSON.parse( res );
                    // 取得した祝日情報を成形
                    const holidayArray = Object.keys( fetchData );
                    const holiday = [];
                    const len = holidayArray.length;
                    for( let i = 0; i < len; i++ ) {
                        holiday.push({
                            title : fetchData[ holidayArray[i] ],
                            start: holidayArray[i],
                            className : "holiday",
                            holiday : holidayArray[i],
                            backgroundColor : "#f27f7f"
                        })
                    }

                    setHolidays( holiday );
                });
            }
        )()
    }, []);

    // 工程の詳細ページへの誘導
    const processNavigate = ( day ) => {

        // urlからID取得
        const id = window.location.href.split( "?" )[2];
        // 送られてきた日付から曜日、その週の日曜日を算出
        const date = new Date( day );
        let dayOfWeek = date.getDay();
        date.setDate( date.getDate() - dayOfWeek );

        const url = window.location.href.split( "?" );
        const processurl = `https://process.paztria.com/NewReading.html?Param1=${date.getMonth()+1}&Param2=${date.getDate()}&Param3=${date.getFullYear()}&Param4=${date.getMonth()}&Param5=${date.getDate()}&Param6=${date.getFullYear()}&Param7=5?${new Date().getTime()}?${id}`
        document.getElementById( "readingPage" ).setAttribute( "href", processurl );
        document.getElementById( "readingPage" ).click();
        //navigate( `/Readprocess?${url[1]}?${url[2]}`, { state :{ url:  processurl  } });
    }

    return (
        <div>
            <FullCalendar
                plugins={[dayGridPlugin, timeGridPlugin, listPlugin,interactionPlugin]}
                initialView="dayGridMonth"
                locales={[jaLocale]}    // todayとかも日本語化される
                locale="ja"             // 日本語化
                businessHours={true}    // 土日の別クラス化
                selectable={true}       // セルクリックの有効化
                editable={true}
                eventClick={function( info ) {
                    processNavigate( info.event._def.extendedProps.holiday );
                }}
                dateClick={function(info){
                    processNavigate( info.dateStr );
                }}
                events={holidays}       // 祝日をイベントとして書き込む
            />
            <a id="readingPage" style={{display : "none"}}></a>
        </div>
    );
}

export default Calendar;