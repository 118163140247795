import logo from './logo.svg';
import './App.css';
import React from 'react'
import {
    BrowserRouter,
    Routes,
    Route,
} from 'react-router-dom'

import Login from "./components/login";
import Home  from './components/home';
import Read from "./components/Readprocess"
import Dictionary from "./components/dictionary";
import Stock from "./components/stockManagement";

/*
function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <a
          className="App-link"
          href="https://reactjs.org"
          target="_blank"
          rel="noopener noreferrer"
        >
          Learn React
        </a>
      </header>
    </div>
  );
}

*/
const App = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/home" element ={<Home />} />
        <Route path="/dictionary" element={<Dictionary />} />
        <Route path="/Readprocess" element={<Read />} />
        <Route path="/stockManagement" element={<Stock />} />
      </Routes>
    </BrowserRouter>
  )
}

export default App;
