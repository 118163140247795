import React, { useState, useEffect } from "react";
import Menu from "./Menu"
import Modal from "./qr_window"

const Stock = () => {

    const [ modalShowState, setModalShowState ] = useState( false );

    return (
        <div>
            <Menu />
            <Modal showState={modalShowState} setShow={setModalShowState}/>
        </div>
    )
}

export default Stock;