import { useState } from "react";
import { useZxing } from "react-zxing";

const BarcodeScanner = () => {
  const [result, setResult] = useState("");
  const { ref } = useZxing({
    onError(ret) {
        console.log('onError::ret=', ret);
    },
    onDecodeError(ret) {
        console.log('onDecodeError::ret=', ret);
    },
    onDecodeResult( result ) {
        setResult( result.getText() );
    },
  });

  return (
    <>
      <video ref={ref} />
      <p>
        <span>Last result:</span>
        <span>{result}</span>
      </p>
    </>
  );
};

export default BarcodeScanner;