import { Link } from "react-router-dom"
import { Document, pdfjs, Page } from "react-pdf"
const pdfViewer = ( props ) => {
    const path = "https://integration.paztria.com/material/pdf/"+ props.link;
    if( props.link == "" ) return;
    return (
        <a href={path} download>{props.link}</a>
    )
}

export default pdfViewer