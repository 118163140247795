import React, { useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

import 'bootstrap/dist/css/bootstrap.min.css'
import Menu from "./Menu"
import Iframe from "react-iframe"

const Readprocess = () => {

    const location = useLocation();
    return (
        <div>
            <Menu />
            <Iframe
                url={location.state.url}
                width='100%'
                height="500px"
            />
        </div>
    )
}

export default Readprocess;