import React, { useState, useEffect, useMemo, useRef } from "react";
import Modal from "react-modal";
import { useZxing  } from "react-zxing";
import BarcodeScanner from "./qr_scanner"

const customStyles = {
    content: {
        top: "20%",
        left: "50%",
        right: "auto",
        bottom: "auto",
        marginRight: "-50%",
        transform: "translate(-50%, -50%)",
        minWidth: "100%",
    },
};
  

const QrWindow = ( props ) => {

    const open = () =>  props.setShow( true );
    const close = () => props.setShow( false );

    const [ scanResult, setResult ] = useState( "" );
    Modal.setAppElement("#root"); // これがないと警告が出る 

    const [ isState, setIsState ] = useState( false );
    const transModal = () => {
        console.log( ref );
        setIsState( !isState )
    };

    const { ref } = useZxing({
        onResult(result) {
          setResult(result.getText());
        },
      });

    /*
    const videoRef = useRef(null);
    const codeReader = useMemo( () => new BrowserMultiFormatReader(), [] );
    useDebounce( async() => {
        if( !videoRef.current ) return;
        await codeReader.decodeFromVideoDevice( undefined, videoRef.current, ( result, error ) => {
            if( !result ) return;
            if( error ) {
                console.log( "ERROR!! : ", error );
                return;
            }
            onReadCode?.(result)
        })
    }, 2000 );
    */

    return (
        <div>
            <button onClick={transModal}>開く</button>
            <Modal
                isOpen = { isState }
                onRequestClose={()=>setIsState( false )}
            >
               <BarcodeScanner/>
                <button onClick={transModal}>閉じる</button>
            </Modal>
        </div>
    )

}

export default QrWindow;