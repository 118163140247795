import React, { useState } from 'react'
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from "react-bootstrap/Button"
import Input from "react-bootstrap/InputGroup"

const InputBox = () => {

    const [ state, setState ] = useState( "" );

    return (
        <div style={{textAlign: "center"}}>
            キーワード検索：<input
                        type="text"
                        value={state}
                        id='conditions'
                        onChange={(e) => setState(e.target.value)}
                    />
        </div>
    )
}

export default InputBox