import React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import Button from "react-bootstrap/Button"

const selectContent = ( props ) => {
    const regist = ( data, user ) => {
        console.log( user );
        const form = new FormData;
        form.set( "word", data.word );
        form.set( "user", user["NAME"] );
        
        const postData = {
            method : "POST",
            body : form
        }

        fetch( "https://integration.paztria.com/cgi/registKintone.php", postData )
        .then( res => res.text() )
        .then( res => {
            //console.log( JSON.parse( res ) );
        })
    }
    return(
        <div className="d-grid gap-2" style={{margin:"5% 5%"}}>
            <Button variant="outline-primary" size="lg" onClick={ () => {
                    regist( props.data, props.user );
                    props.modalFunc(props.data ); 
                }}>
                {props.data.word}
            </Button>
        </div>
    )
}

export default selectContent;