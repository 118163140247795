import React, { useState, useEffect } from "react"
import Button from "react-bootstrap/Button"

const FrequentlyOccurring = ({data, modalFunc, viewData}) => {

    return(
        <Button variant="outline-primary" style={{margin:"5px 5px"}}onClick={ () => {
            modalFunc(data);
        }}>{data["word"]}
        </Button>
    )
}

export default FrequentlyOccurring;